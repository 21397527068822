import * as React from "react";
import "../styles/style.css";

// markup
const IndexPage = () => {
  const toggleNav = () => {
    const mobileNav = document.getElementById("mobileNav");
    mobileNav.classList.toggle("visible");
  };
  return (
    <main>
      <title>Alexandra Rusu - Medic Ortodont</title>
      <header>
        <span>Dr. Alexandra Rusu</span>
        <nav>
          <a href="/">Prima pagina</a>
          <a href="/about">Despre mine</a>
          <a href="/cases">Cazuri</a>
          <a href="/treatment">Tratamente și tarife</a>
          <a href="/contact">Contact</a>
        </nav>
        <button className="menu-trigger" onClick={toggleNav}>
          Menu
        </button>
      </header>
      <div className="mobile-nav" id="mobileNav">
        <nav>
          <a href="/">Prima pagina</a>
          <a href="/about">Despre mine</a>
          <a href="/cases">Cazuri</a>
          <a href="/treatment">Tratamente și tarife</a>
          <a href="/contact">Contact</a>
        </nav>
      </div>
      <section className="hero">
        <h1>
          Alexandra Rusu
          <br />
          <span>— medic specialist ortodonție</span>
        </h1>
        <div className="image"></div>
        <div className="image image-bg"></div>
        <h3>
          Nimic din ceea ce porți nu este mai important decât zâmbetul tău
        </h3>
        <a href="/about">Mai multe despre mine</a>
      </section>
      <section className="about">
        <div className="col">
          <h3>Ortodonția</h3>
          <p>
            Este o specializare a stomatologiei care se ocupă cu alinierea
            dinților, corectarea mușcăturii și modificarea poziției oaselor
            maxilare.
          </p>
        </div>
        <div className="col">
          <h3>Tratamentul</h3>
          <p>
            Se poate realiza cu aparate mobilizabile sau fixe (de ex:
            bracketsi).
          </p>
        </div>
        <div className="col">
          <h3>Primul consult</h3>
          <p>
            Este indicat a se realiza în jurul vârstei de 6 ani dar este posibil
            în orice moment al vieții. Nu exista o limita de vârstă pentru a
            corecta o problemă ortodontică.
          </p>
        </div>
      </section>
      <section className="cases">
        <h3>Pacienții mei</h3>
        <div className="images">
          <div className="image image1">
            <span>Alexandra</span>
          </div>
          <div className="image image2">
            <span>Ioana</span>
          </div>
          <div className="image image3">
            <span>Diana F</span>
          </div>
          <div className="image image4">
            <span>Diana P</span>
          </div>
        </div>
        <a href="/cases">Vezi before & after</a>
      </section>
      <section className="treatments">
        <a href="/treatment">Vezi tratamente și tarife</a>
      </section>
      <footer>
        <div className="col">
          <h3>
            Alexandra Rusu
            <br />
            <span>— medic specialist ortodonție</span>
            <br />
            <small>© 2021</small>
          </h3>
        </div>
        <div className="col">
          <a href="/contact">Contact</a>
        </div>
      </footer>
    </main>
  );
};

export default IndexPage;
